import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store";
import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import "antd/dist/antd.css";
import "./Assets/styles/font/stylesheet.css";
import "./Assets/styles/css/main.css";
import "reactflow/dist/style.css";

import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { ApolloProvider } from "@apollo/client";
import { L10n, loadCldr, registerLicense } from "@syncfusion/ej2-base";
import client from "src/graphql";
import { trLOCALES } from "./_localization/syncfusion/tr_loc";

console.log("syncfusionTrLocales->", trLOCALES);
setChonkyDefaults({ iconComponent: ChonkyIconFA });
// registerLicense("Replace your generated license key here");
registerLicense("Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXtcdnRRQ2ddVUVzXUI=");

loadCldr(
  require("./_localization/cldr-data/supplemental/numberingSystems.json"),
  require("./_localization/cldr-data/main/tr/ca-gregorian.json"),
  require("./_localization/cldr-data/main/tr/numbers.json"),
  require("./_localization/cldr-data/main/tr/timeZoneNames.json")
);

L10n.load({
  tr: trLOCALES,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
